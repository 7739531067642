<div class="flex-container flex-column gap-4">
    @if (label) {
        <label
            [for]="dropdownId"
            [class.font-body-sm-regular]="small"
            [class.font-body-md-regular]="!small">
            {{ label }}
            @if (formControl?.hasValidator(Validators.required) || required) {
                <span>*</span>
            }
        </label>
    }
    <p-dropdown
        #dropdown
        [id]="dropdownId"
        [class.small]="small"
        [class.medium]="!small"
        [class.filled]="selectedItem"
        [class.error]="formControl?.invalid"
        [placeholder]="placeholder | translate"
        [options]="options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [filter]="showFilter"
        [filterBy]="filterBy"
        [showClear]="showClear"
        [(ngModel)]="selectedItem"
        (onChange)="changeSelectedItem()"
        [disabled]="formControl?.disabled || disabled"
        (onClear)="clearDropdown()"
        appendTo="body">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            @if (selectedItem != null) {
                <div [class]="selectedItemColor">
                    {{ selectedOption && optionLabel ? selectedOption[optionLabel] : selectedItem }}
                </div>
            }
        </ng-template>

        <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
                {{ optionLabel ? option[optionLabel] : option }}
            </div>
        </ng-template>
    </p-dropdown>
    <ng-content></ng-content>
    @if (formControl) {
        <ng-container>
            <app-validation-messages [name]="label" [control]="formControl">
            </app-validation-messages>
        </ng-container>
    }
</div>
