import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrl: './dropdown.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
})
export class DropdownComponent implements ControlValueAccessor {
    onChange = (changes: any) => {};
    onTouched = () => {};

    @Input() dropdownId: string;
    @Input() label: string;
    @Input() options: any[];
    @Input() placeholder = 'DEFAULT_TEXT.DropdownPlaceholder';
    @Input() disabled = false;
    @Input() showClear = true;
    @Input() showFilter = true;
    @Input() small = false;
    @Input() formControl?: FormControl;
    @Input() optionLabel?: string;
    @Input() optionValue?: string;
    @Input() filterBy?: string;
    @Input() colorFunction?: (selected: any) => string;
    @Input() required? = false;

    @Output() valueChange = new EventEmitter();
    @Output() clear = new EventEmitter();

    selectedItem: any;
    selectedItemColor: string = '';
    previousSelectedItem: any;
    Validators = Validators;

    writeValue(selectedItem: any): void {
        this.selectedItem = selectedItem;
        this.onChange(this.selectedItem);

        this.updateSelectedColor();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    changeSelectedItem(): void {
        this.onChange(this.selectedItem);
        this.valueChange.emit(this.selectedItem);

        this.updateSelectedColor();
    }

    clearDropdown(): void {
        this.clear.emit();
    }

    updateSelectedColor(): void {
        if (this.colorFunction && this.selectedItem) {
            this.selectedItemColor = this.colorFunction(this.selectedItem) + ' colorIsSelected';
        }
    }

    getSelectedColorClass(): string {
        return this.selectedItemColor ? this.selectedItemColor : '';
    }
}
