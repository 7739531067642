<div
    [ngClass]="{
        'dynamic-dialog-with-footer': actionButtonText || cancelButtonVisible || data?.buttons,
    }">
    <ng-content></ng-content>
</div>

@if (actionButtonText || cancelButtonVisible || data?.buttons) {
    <div class="dialog-footer">
        <div class="action-buttons">
            @if (actionButtonText) {
                <button
                    pButton
                    id="actionButton"
                    type="button"
                    [label]="actionButtonText | translate"
                    class="medium filled"
                    (click)="makeAction()"
                    [disabled]="actionButtonDisable"></button>
            }

            @if (cancelButtonVisible) {
                <button
                    pButton
                    id="cancelButton"
                    type="button"
                    [label]="buttonText | translate"
                    class="medium ghost gray"
                    (click)="close()"></button>
            }
            @if (data) {
                @for (button of data.buttons; track button; let i = $index) {
                    <button
                        pButton
                        pRipple
                        [id]="'button-' + i"
                        type="button"
                        [class]="[button.type, 'medium']"
                        (click)="action(button)"
                        [disabled]="button.disableFunction">
                        @if (button.icon) {
                            <i [class]="button.icon"></i>
                        }
                        {{ button.label | translate }}
                    </button>
                }
            }
        </div>
    </div>
}
