export enum Actions {
    Add = 'add',
    Delete = 'delete',
    Import = 'import',
    Update = 'update',
    View = 'view',
    Duplicate = 'duplicate',
    Approve = 'approve',
    Cancel = 'cancel',
    Conclude = 'conclude',
    SystemManagement = 'systemManagement',
    AdministrativeApprove = 'administrativeApprove',
    Justify = 'justify',
    HourMeter = 'hourMeter',
}
