import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { ColorLegendModel } from '@app/shared/components/global-color-legend/color-legend.model';
import { DropdownActionModel } from '@app/shared/models/dropdown-action.model';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { TableFiltersModel } from '@app/shared/models/paginated-request.model';
import { TableDataUtilsService } from '@app/shared/services/table-data-utils.service';
import { TranslateService } from '@app/shared/services/translate.service';
import dayjs from 'dayjs';
import { LazyLoadEvent } from 'primeng/api';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalTableBaseComponent implements OnChanges {
    @Input() originalCols: CustomTableColumnModel[];
    _data: any[];
    get data(): any[] {
        return this._data;
    }
    @Input() set data(value: any[]) {
        this._data = value;
        this.preSelectItems();
    }
    @Input() canEdit = true;
    @Input() legends?: ColorLegendModel[];
    @Input() backgroundColorFunction = (): string => {
        return '';
    };
    @Input() spanBackgroundColorFunction = (): string => {
        return '';
    };
    @Input() canceledStyle?: Function;
    @Input() dropdownActions?: DropdownActionModel[];
    @Input() isRowSelectable?: Function;
    @Input() isLoading?: boolean;
    @Input() getColumnCache?: Function;
    @Input() setColumnCache?: Function;
    @Input() showFilterRow?: boolean;
    @Input() filter? = new TableFiltersModel();
    @Input() selectedItems: any[] = [];
    @Input() tableId: string;
    @Input() showCustomizeColumns = false;
    @Input() showExportTable = false;

    @Output() searchFunction: EventEmitter<TableFiltersModel> = new EventEmitter();
    @Output() selectedItem?: EventEmitter<any[]> = new EventEmitter();
    @Output() removeItem?: EventEmitter<any[]> = new EventEmitter();
    @Output() removeAll?: EventEmitter<any[]> = new EventEmitter();

    @HostBinding('class.height-40') @Input() height40 = false;
    @HostBinding('class.min-height-40') @Input() minHeight40 = false;
    @HostBinding('class.height-50') @Input() height50 = false;
    @HostBinding('class.page-with-tab-navigation') @Input() pageWithTabNavigation = false;
    @HostBinding('class.max-height-40') @Input() maxHeight40 = false;

    TableColTypes = TableColTypes;
    cols: CustomTableColumnModel[];

    dateFormat: string;
    lastEvent: LazyLoadEvent;

    booleanOptions: {
        label: string;
        value: boolean;
    }[];

    preSelectItems = (): void => {};

    constructor(
        protected tableDataUtilsService: TableDataUtilsService,
        protected translateService: TranslateService
    ) {}

    async initData(): Promise<void> {
        this.dateFormat = await this.translateService.getTranslation('primeng.dateFormatDayJs');
        this.booleanOptions = [
            {
                label: await this.translateService.getTranslation('DEFAULT_TEXT.Yes'),
                value: true,
            },
            {
                label: await this.translateService.getTranslation('DEFAULT_TEXT.No'),
                value: false,
            },
        ];

        this.setColumns();
    }

    setColumns(): void {
        if (this.getColumnCache) {
            this.cols = this.tableDataUtilsService.setOrderColumns(
                this.originalCols,
                this.getColumnCache()
            );
        } else {
            this.cols = this.originalCols.filter(({ data }) => !data.hideColumn);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.originalCols) {
            this.setColumns();
        }
    }

    onSelectItem(): void {
        this.selectedItem.emit(this.selectedItems);
    }

    onRemoveItem(event: any): void {
        if (this.canEdit) {
            this.removeItem.emit(event);
        }
    }

    onRemoveAll(): void {
        if (this.canEdit) {
            this.removeAll.emit();
        }
    }

    applyFirstStickyColumnClass(col: CustomTableColumnModel): boolean {
        return (
            col.data.type === TableColTypes.Selection ||
            col.data.type === TableColTypes.DropdownMenu ||
            col.data.type === TableColTypes.Remove
        );
    }

    formatTooltip(item: any, column: CustomTableColumnModel): string {
        const value = item[column.data.field];
        if (column.data.type == TableColTypes.TextList) {
            return value.join(' | ');
        } else if (column.data.type == TableColTypes.Date) {
            return new Date(value).toLocaleDateString();
        } else if (column.data.type == TableColTypes.DateRange) {
            return this.getDateRangeString(item);
        } else if (
            column.data.type == TableColTypes.Boolean ||
            column.data.type == TableColTypes.Bookmark
        ) {
            return value ? 'DEFAULT_TEXT.Yes' : 'DEFAULT_TEXT.No';
        } else if (column.data.type == TableColTypes.LargeText) {
            return '';
        } else if (Array.isArray(value)) {
            return value.join(',');
        } else if (column.data.type == TableColTypes.Percentage) {
            return value == null ? '' : `${value.toFixed(column.data.fixedDigits)}%`;
        }

        return value;
    }

    getDateRangeString(item: { start: Date; end: Date }): string {
        return `${dayjs(item.start).format(this.dateFormat)} - ${dayjs(item.end).format(
            this.dateFormat
        )}`;
    }

    showFilterAtColumn(type: TableColTypes): boolean {
        return ![
            TableColTypes.Boolean,
            TableColTypes.Selection,
            TableColTypes.DropdownMenu,
            TableColTypes.Remove,
            TableColTypes.Bookmark,
            TableColTypes.Show,
            TableColTypes.Toggle,
        ].includes(type);
    }

    columnFilterType(type: TableColTypes): string {
        if (TableColTypes.Toggle === type) return 'boolean';
        if (TableColTypes.Bookmark === type) return 'boolean';
        return 'text';
    }

    onColumnsVisibilityChange(columns: CustomTableColumnModel[]): void {
        this.cols = columns;
        this.saveColumnsInCache(columns);
    }

    saveColumnsInCache(columns: CustomTableColumnModel[]): void {
        if (this.setColumnCache) {
            this.setColumnCache(columns);
        }
    }
}
