import { Actions } from '@app/core/utils/enums/actions.enum';
import { JustificationType } from '@app/core/utils/enums/justification-type.enum';
import { PlannedDatesHistoryTypeEnum } from '@app/core/utils/enums/planned-dates-history-type.enum';
import { RequiredJustificativeEnum } from '@app/core/utils/enums/required-justificative.enum';
import { ShiftTypes } from '@app/core/utils/enums/shift-types.enum';
import { SelectedScopeFormModel } from '@app/shared/models/selected-scope.model';
import { FunctionalLocationModel } from './functional-location.model';
import { ShutdownInfoModel } from './shutdown-info.model';

export class MaintenanceShutdownFormModel {
    public previousStartDate?: Date = null;
    public previousEndDate?: Date = null;
    constructor(
        public name: string,
        public planningCenter: string,
        public localizationCenter: string,
        public phase: number,
        public shift: ShiftTypes,
        public isBigShutdown: boolean,
        public startDate: Date,
        public endDate: Date,
        public coiInformations: ShutdownInfoModel,
        public observation: string,
        public fullDescription: string,
        public isProductionProcessImpact: boolean,
        public isIndirectShutdown: boolean,
        public isStrategicShutdownEngineering: boolean,
        public createdDate?: Date,
        public actualStart?: Date,
        public actualEnd?: Date
    ) {}
}

export class ShutdownManagementFormModel extends MaintenanceShutdownFormModel {
    constructor(
        public functionalLocations?: FunctionalLocationModel[],
        public functionalLocationGroupId?: number,
        public functionalLocationsGroupExceptions?: FunctionalLocationModel[],
        public justificative?: JustificativeChangePlannedDatesModel,
        public nonAdherenceJustificative?: NonAdherenceJustificativeModel,
        public nonAdherencePlannedDatesHistoryJustificatives?: PlannedDatesHistoryJustificativeModel[],
        public activities = new ActivitiesModel(),
        public locked?: boolean,
        public projectDemand = new ShutdownProjectDemandModel(),
        public owner?: string,
        public defaultAlternate?: string,
        public scopeEditors?: string,
        public id?: number
    ) {
        super(
            '',
            '',
            '',
            null,
            ShiftTypes.TwentyFourHours,
            false,
            new Date(),
            new Date(new Date().setHours(new Date().getHours() + 1)),
            new ShutdownInfoModel(),
            '',
            '',
            true,
            false,
            false
        );
    }
}

export class MaintenanceShutdownFormResponseModel extends MaintenanceShutdownFormModel {
    constructor(
        public id: number,
        public owner: string,
        public defaultAlternate: string,
        public scopeEditors: string,
        public functionalLocations: FunctionalLocationModel[],
        public functionalLocationGroupId: number,
        public sapRevision: string,
        public locked: boolean
    ) {
        super(
            '',
            '',
            '',
            null,
            ShiftTypes.TwentyFourHours,
            false,
            new Date(),
            new Date(new Date().setHours(new Date().getHours() + 1)),
            new ShutdownInfoModel(),
            '',
            '',
            true,
            false,
            false
        );
    }
}

export class JustificationCheckModel {
    constructor(
        public requiredJustificatives: RequiredJustificativeEnum[],
        public justificationType: JustificationType,
        public message: string
    ) {}
}

export class ShutdownStatusCheckModel {
    isAdherentByDate: boolean;
    isAdherentByDuration: boolean;
    plannedDatesHistoryTypeId: PlannedDatesHistoryTypeEnum[];
}

export class JustificativeChangePlannedDatesModel {
    createdAt?: Date;
    previousStartDate?: Date;
    previousEndDate?: Date;
    justificativeByDate = '';
    justificativeIdByDate: number = null;
    riskAnalysisByDate = '';
    reasonByDate: number = null;
    reasonTextByDate: string = null;
    justificativeByDuration = '';
    justificativeIdByDuration: number = null;
    riskAnalysisByDuration = '';
    reasonByDuration: number = null;
    reasonTextByDuration: string = null;
    justificativeNameByDate?: string;
    justificativeNameByDuration?: string;
    observation?: string;
}

export class NonAdherenceJustificativeModel {
    durationReasonId: number;
    durationJustificative: string;
    dateReasonId: number;
    dateJustificative: string;
}

export class ShutdownProjectDemandModel {
    constructor(
        public added: number[] = [],
        public removed: number[] = []
    ) {}
}

export class ActivitiesModel {
    added: SelectedScopeFormModel[] = [];
    removed: SelectedScopeFormModel[] = [];
    updated: SelectedScopeFormModel[] = [];
}

export class PlannedOrdersModel {
    added: PlannedOrderItemModel[];
    removed: PlannedOrderItemModel[];
}

export class PlannedOrderItemModel {
    id: number;
    orderNumber: string;
    alocatedManHours: number;
    suggestedDate: Date;
}

export class JustificativeDataModel {
    id: number;
    name: string;
}

export class InitialValuesModel {
    data: MaintenanceShutdownFormResponseModel | ShutdownManagementFormModel;
    action: Actions;
}

export class PlannedDatesHistoryJustificativeModel {
    plannedDatesHistoryTypeId: number;
    changeDateJustificativeId: number;
    changeDateJustificativeOtherText: string;
    changeDurationJustificativeId: number;
    changeDurationJustificativeOtherText: string;
}
