import { Injectable } from '@angular/core';
import { TranslateService as TranslateServiceCore } from '@ngx-translate/core';
import { SupportedLanguages } from '@utils/enums/supported-languages.enum';
import { loadMessages, locale } from 'devextreme/localization';
import enMessages from 'devextreme/localization/messages/en.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    private locale: string;
    readonly LANGUAGE_STORAGE_KEY = 'lang';

    constructor(
        private translateServiceCore: TranslateServiceCore,
        private primeNGConfig: PrimeNGConfig
    ) {
        this.initLocalization();

        const storedLanguage = localStorage.getItem(this.LANGUAGE_STORAGE_KEY);

        if (storedLanguage === 'undefined') {
            localStorage.removeItem(this.LANGUAGE_STORAGE_KEY);
        }

        const defaultLanguage = storedLanguage || SupportedLanguages.PT;

        this.translateServiceCore.setDefaultLang(defaultLanguage);

        this.translateServiceCore.onLangChange.subscribe(() => {
            this.setLanguage(this.getCurrentLanguage());
        });

        this.getPrimeNgConfig().subscribe(res => {
            this.primeNGConfig.setTranslation(res);
        });
    }

    async setLanguage(language: SupportedLanguages): Promise<void> {
        localStorage.setItem(this.LANGUAGE_STORAGE_KEY, language);
        await this.translateServiceCore.use(language);
        parent.document.location.reload();
    }

    getCurrentLanguage(): SupportedLanguages {
        return (
            (localStorage.getItem(this.LANGUAGE_STORAGE_KEY) as SupportedLanguages) ??
            SupportedLanguages.PT
        );
    }

    async getTranslation(key: string): Promise<any> {
        return await lastValueFrom(this.translateServiceCore.get(key));
    }

    getPrimeNgConfig(): Observable<string | any> {
        return this.translateServiceCore.get('primeng');
    }

    initLocalization(): void {
        this.locale = this.getCurrentLanguage();
        loadMessages(enMessages);
        loadMessages(ptMessages);
        locale(this.locale);
    }
}
